<template>
    <v-container fluid>
        <TopCard 
            text="Course Categories"
            createable
            reloadable
            @reload="reload()"
        />

        <br>

        <TableCard
            :headers="headers"
            :items="courseCategories"
            :loading="isLoading"
            :filterStatus="statuses"
            viewable
            deleteable
            searchable
            multiple
            mainField="name"
            @delete="deleteItem"
            @view="viewItem"
            @filterByStatus="filterByStatus"
        />
    </v-container>
</template>

<script>
import TopCard from '../../../components/Cards/TopCard.vue'
import TableCard from '../../../components/Cards/TableCard.vue'
import { STATUSES_SELECTION, STATUSES_VALUES } from '../../../helpers/Status'

export default {
    components: {
        TopCard,
        TableCard
    },

    data() {
        return {
            headers: [
                { text: 'Name',         value: 'name' },
                { text: 'Courses',      value: 'courses_count' },
                { text: 'Created At',   value: 'created_at' },
                { text: 'Status',       value: 'status',    align: 'right' },
                { text: 'Actions',      value: 'actions',   align: 'right' },
            ],
            search: '',
            statuses: STATUSES_SELECTION,
            filterStatuses: STATUSES_VALUES
        }
    },

    computed: {
        courseCategories() {
            let courseCategories    = this.$store.getters['CourseCategoryState/courseCategories'];
            let courses             = this.$store.getters['CourseState/courses'];

            if(!courseCategories || !courses) {
                return [];
            }

            courseCategories.forEach(category => {
                category.deleteDisabledMessage  = category.courses_count ? 'Cannot delete Course Category that is being used' : '';
                category.courses_count          = courses.filter(course => course.category_id === category.id).length
            });

            // filter by status
            return courseCategories.filter(lesson => this.filterStatuses.includes(lesson.status))
        },

        isLoading() {
            return !this.$store.getters['CourseCategoryState/courseCategories'];
        }
    },

    methods: {
       
        deleteItem(data) {
            this.$store.dispatch('CourseCategoryState/deleteCourseCategories', data)
        },

        viewItem(item) {
            this.$router.push('/content/course-categories/show/' + item.id)
        },

        filterByStatus(statuses) {
            this.filterStatuses = statuses;
        },

        reload() {
            this.$store.dispatch('CourseCategoryState/getCourseCategories');
        }
    }
}
</script>

<style scoped>
</style>